import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../components/MenuNav.css';

const MenuNav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="nav">
            <Link to="/">
                <img src="/resources/logo.png" alt="Logo Agencia Muza"/>
            </Link>
            <div className="menu-icon" onClick={toggleMenu}>
                <span>Menú</span>
                <div className="hamburger">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
            <ul className={`menuNav ${isOpen ? 'open' : ''}`}>
              <li className="menuItem"><Link to="/" className="menuLink">Home</Link></li>
              <li className="menuItem"><Link to="/#link-elegirnos" className="menuLink">Somos</Link></li>
              <li className="menuItem"><Link to="/#link-servicios" className="menuLink">Servicios</Link></li>
              <li className="menuItem"><Link to="/proyectos" className="menuLink">Proyectos</Link></li>
              <li className="menuItem"><Link to="/#section-testimonios" className="menuLink">Testimonios</Link></li>
              {/*<li className="menuItem"><Link to="/blog" className="menuLink">Blog</Link></li>*/}
            </ul>
            <ul className={`menuNav ${isOpen ? 'open' : ''}`}>
              <li className="menuItem"><Link to="/contacto" className="menuLink">Contacto</Link></li>
            </ul>
            
        </nav>
    );
};

export default MenuNav;