import { InputText } from 'primereact/inputtext';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { RadioButton } from "primereact/radiobutton";
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useState, useEffect } from "react";
import './styles.css';
import './fonts/fonts.css';
import { Checkbox } from "primereact/checkbox"
import MenuNav from './components/MenuNav';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';




function Blog() {

    const [inputValue, setInputValue] = useState('');

  // Manejar el cambio en el input
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Manejar el clic del botón
  const handleButtonClick = () => {
    alert(`Button clicked with input value: ${inputValue}`);
  };


  const [activeTab, setActiveTab] = useState('nav-home');
  
  const handleTabClick = (tabId) => {
      setActiveTab(tabId);
    };


    return (

        <div>
            <MenuNav/>


{/* Cuadro Blog */}

    <article className="contenido-blog justify-content-center">
    <header className="sub-header">
        <div>
        <h2 className="subtitle-blog">Untitled Blog</h2>
        </div>
        <div>
        <p className='text-sub'>New product features, the lastest in technology, solutions, and updates.</p>
        </div>
    </header>


    
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Enter your email"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
        value={inputValue}
        onChange={handleInputChange}
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        onClick={handleButtonClick}
      >
        Subscribe
      </button>
    </div>


    <nav>
        <div className="nav-blog nav-tabs" id="nav-tab" role="tablist">
          <button
            className={`nav-link ${activeTab === 'nav-all' ? 'active' : ''}`}
            id="nav-all-tab"
            onClick={() => handleTabClick('nav-all')}
            type="button"
            role="tab"
            aria-controls="nav-all"
            aria-selected={activeTab === 'nav-all'}
          >
            View all
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-design' ? 'active' : ''}`}
            id="nav-design-tab"
            onClick={() => handleTabClick('nav-design')}
            type="button"
            role="tab"
            aria-controls="nav-design"
            aria-selected={activeTab === 'nav-design'}
          >
            Design
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-product' ? 'active' : ''}`}
            id="nav-product-tab"
            onClick={() => handleTabClick('nav-product')}
            type="button"
            role="tab"
            aria-controls="nav-product"
            aria-selected={activeTab === 'nav-product'}
          >
            Product
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-development' ? 'active' : ''}`}
            id="nav-development-tab"
            onClick={() => handleTabClick('nav-development')}
            type="button"
            role="tab"
            aria-controls="nav-development"
            aria-selected={activeTab === 'nav-development'}
          >
            Development
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-customer' ? 'active' : ''}`}
            id="nav-customer-tab"
            onClick={() => handleTabClick('nav-customer')}
            type="button"
            role="tab"
            aria-controls="nav-customer"
            aria-selected={activeTab === 'nav-customer'}
          >
            Customer Support
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-leadership' ? 'active' : ''}`}
            id="nav-leadership-tab"
            onClick={() => handleTabClick('nav-leadership')}
            type="button"
            role="tab"
            aria-controls="nav-leadership"
            aria-selected={activeTab === 'nav-leadership'}
          >
            Leadership
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-management' ? 'active' : ''}`}
            id="nav-management-tab"
            onClick={() => handleTabClick('nav-management')}
            type="button"
            role="tab"
            aria-controls="nav-management"
            aria-selected={activeTab === 'nav-management'}
          >
            Management
          </button>
          <button
            className={`nav-link ${activeTab === 'nav-interviews' ? 'active' : ''}`}
            id="nav-interviews-tab"
            onClick={() => handleTabClick('nav-interviews')}
            type="button"
            role="tab"
            aria-controls="nav-interviews"
            aria-selected={activeTab === 'nav-interviews'}
          >
            Interviews
          </button>
          
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className={`tab-pane fade ${activeTab === 'nav-all' ? 'show active' : ''}`}
          id="nav-all"
          role="tabpanel"
          aria-labelledby="nav-all-tab"
        >
          {/* Content for All Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-design' ? 'show active' : ''}`}
          id="nav-design"
          role="tabpanel"
          aria-labelledby="nav-design-tab"
        >
          {/* Content for Design Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-product' ? 'show active' : ''}`}
          id="nav-product"
          role="tabpanel"
          aria-labelledby="nav-product-tab"
        >
          {/* Content for Product Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-development' ? 'show active' : ''}`}
          id="nav-development"
          role="tabpanel"
          aria-labelledby="nav-development-tab"
        >
          {/* Content for Development Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-customer' ? 'show active' : ''}`}
          id="nav-customer"
          role="tabpanel"
          aria-labelledby="nav-customer-tab"
        >
          {/* Content for Customer Support Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-leadership' ? 'show active' : ''}`}
          id="nav-leadership"
          role="tabpanel"
          aria-labelledby="nav-leadership-tab"
        >
          {/* Content for Leadership Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-management' ? 'show active' : ''}`}
          id="nav-management"
          role="tabpanel"
          aria-labelledby="nav-management-tab"
        >
          {/* Content for Management Tab */}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'nav-interviews' ? 'show active' : ''}`}
          id="nav-interviews"
          role="tabpanel"
          aria-labelledby="nav-interviews-tab"
        >
          {/* Content for Interviews Tab */}
        </div>
      </div>

      <section className="section-imgblog">

        <div className="container-bubbles">
          <img src="resources/bubble_2.png" id="bubble-1" className="bubbles"/>
          <img src="resources/bubble_2.png" id="bubble-2" className="bubbles"/>

       <div className='contenedor-imgblog'>
        <div className='row'>
            <div className='col-12 col-md-6 col-lg-4'>
                <div className='img-blog'>
                    <img src={process.env.PUBLIC_URL + '/content.jpg'} alt="Review presentations"/>
                    <div className='overlay'>
                        <p>Olivia Rhye</p>
                    </div>
                    <h5>UX review presentations</h5>
                    <p>How do you create compelling presentations that wow your colleagues and impress your managers? Look no further</p>
                </div>
            </div>

            <div className='col-12 col-md-6 col-lg-4'>
                <div className='img-blog'>
                    <img src={process.env.PUBLIC_URL + '/libros-diseño-gráficp.png'} alt="Best books"/>
                    <div className='overlay'>
                        <p>Phoenix Baker</p>
                    </div>
                    <h5>Best books on scalling your startup</h5>
                    <p>This collection of the best startup books for scalling your startup are packed full with valuable insights and advice.</p>
                </div>
            </div>
        </div>

            <div className='col-12 col-md-6 col-lg-4'>
                <div className='img-blog'>
                    <img src={process.env.PUBLIC_URL + '/libros-diseño-gráficp.png'} alt="Best books"/>
                    <div className='overlay'>
                        <p>Phoenix Baker</p>
                    </div>
                    <h5>Best books on scalling your startup</h5>
                    <p>This collection of the best startup books for scalling your startup are packed full with valuable insights and advice.</p>
                </div>
            </div>

            <div className='col-12 col-md-6 col-lg-4'>
                <div className='img-blog'>
                    <img src={process.env.PUBLIC_URL + '/libros-diseño-gráficp.png'} alt="Best books"/>
                    <div className='overlay'>
                        <p>Phoenix Baker</p>
                    </div>
                    <h5>Best books on scalling your startup</h5>
                    <p>This collection of the best startup books for scalling your startup are packed full with valuable insights and advice.</p>
                </div>
            </div>
        </div>

    </div>
    </section>


    </article>

            <Footer/>
        </div>
    )
}



  


export default Blog