import logo from './logo.svg';
import './App.css';
import Spline from '@splinetool/react-spline';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './fonts/fonts.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect } from "react";
import App2 from './contacto'; 
import MenuNav from './components/MenuNav';
import ButtonClassic from './components/ButtonClassic';
import { Link } from 'react-router-dom';
import Footer from './components/Footer';
import ChatContainer from './components/ChatContainer';
import Carousel from "react-multi-carousel";
import ProyectoItem2 from './components/ProyectoItem2';
import { useLocation } from 'react-router-dom';
import './components/ProyectoItem2'

function App3() {
  
const [hoveredProject, setHoveredProject] = useState(null);

  const handleMouseEnter = (projectId) => {
    setHoveredProject(projectId);
  };

  const handleMouseLeave = () => {
    setHoveredProject(null);
  };

  const getProjectContent = (projectId) => {
    switch (projectId) {
      case 1:
        return <div>
          <Link to="/proyectos">
            <img src={process.env.PUBLIC_URL + '/resources/delservice.png'} alt="Imagen del proyecto Dashboard Delivery P.S." className="txt-box-img"/>
          </Link>
        </div>;
      case 2:
        return <div>
          <Link to="/proyectos">
            <img src={process.env.PUBLIC_URL + '/resources/gtiservice.png'} alt="Imagen del proyecto Página Web GTI" className="txt-box-img"/>
          </Link>
        </div>;
      case 3:
        return <div>
          <Link to="/proyectos">
            <img src={process.env.PUBLIC_URL + '/resources/cvoservice.png'} alt="Imagen del proyecto Dashboard CVO" className="txt-box-img"/>
          </Link>
        </div>;
      case 4:
        return <div>
          <Link to="/proyectos">
            <img src={process.env.PUBLIC_URL + '/resources/iyfservice.png'} alt="Imagen del proyecto Página Web IYF" className="txt-box-img" />
          </Link>
        </div>;
      default:
        return null;
    }
  };
  const location = useLocation();

  useEffect(() => {
      if (location.hash) {
          const element = document.getElementById(location.hash.substring(1));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);

  return (
    <div>
     <MenuNav/>

     <section id="home" className="section-main align-items-strech">
        <main>
          <header className="section-main-header">
            <h1>Diseño y desarrollo web: </h1>
            <h1>Tu presencia web a tu medida</h1>
            
            <p>¿Sueñas con una página web que atraiga clientes, venda productos y haga crecer tu negocio?</p>
          </header>
        <Link to="/#link-servicios">
        <Button className="button-classic">Servicios </Button>
        </Link>
          
          <div className="App App-header spline-container">
            <div className='spline-animation'>
            <Spline scene="https://prod.spline.design/i-V5LiqKp2rq1C9j/scene.splinecode"></Spline>
            </div>
          </div>

          <div className="section-main-info">
            <p>En <span className="font-bold">Muza</span>, somos expertos en <span className="font-bold-blue">crear experiencias digitales únicas</span> que impulsan tu negocio. Te ofrecemos soluciones integrales: <h2 className="font-bold-blue1">diseño de interfaces web <span className='font-bold-blue'>y desarrollo full stack,</span></h2><h2 className='font-bold-blue1'> diseños para páginas web</h2> que sean atractivas y funcionales,<h2 className='font-bold-blue1'> diseño web responsive</h2> <span className="font-bold-blue"> y gestión segura de <h2 className='font-bold-blue1'>bases de datos</h2></span>, entre otras.</p>

            <p className="font-bold">¿Listo para crear una experiencia digital única?</p>

            <p>Contacta con Muza y llévate el 10% de descuento en tu primera compra por tiempo limitado usando el código <span className="font-bold">MUZADES10</span></p>
            <Link to="/contacto">
            <button className="button-classic">10% descuento</button>
            </Link>
            <p>Lleva tu negocio al <span className="font-bold">¡SIGUIENTE NIVEL!</span></p>
          </div>
        </main>
      </section>

{/* SECCION ELEGIRNOS */}

<section id="link-elegirnos" className="section-elegirnos">
        <header className="subtitle-header">
          <div className="line-subtitle"></div>
          <h3>¿Por qué elegirnos?</h3>
          <div className="line-subtitle"></div>
        </header>
  
  <ChatContainer/>
        <Link to="/contacto">
        <button className="button-classic">Contáctanos</button>
        </Link>
      </section> 

{/*SECCION SERVICIOS */}

      <section id="link-servicios" className="section-servicios">
        <header className="subtitle-header">
          <div className="line-subtitle"></div>
          <h3>Servicios</h3>
          <div className="line-subtitle"></div>
        </header>

        <div className="container-bubbles">
          <img src="resources/bubble_2.png" id="bubble-1" className="bubbles"/>
          <img src="resources/bubble_2.png" id="bubble-2" className="bubbles"/>

          <article className="text-boxes-servicios">
            <div className="text-box">
              <h2>Diseño de páginas web</h2>
              <p className="text-box-des">Creamos páginas web atractivas y funcionales que se adaptan a todos los dispositivos.</p>
              <img src={process.env.PUBLIC_URL + '/servicio-1.png'} alt="Interfaz de una página web" className="text-box-img" style={{ width: '100%', height: 'auto', objectFit: 'cover', flexGrow: 1, marginTop: '10px' }}/>
            </div>
            <div className="text-box">
              <h2>Desarrollo web</h2>
              <p className="text-box-des">Desarrollamos aplicaciones web robustas y seguras que satisfacen tus necesidades específicas.</p>
              <img src={process.env.PUBLIC_URL + '/servicio-2.png'} alt="Persona desarrollando una aplicación web" className="text-box-img" style={{ width: '100%', height: 'auto', objectFit: 'cover', flexGrow: 1, marginTop: '10px' }}/>
            </div>
            <div className="text-box">
              <h2>Bases de datos relacionales</h2>
              <p className="text-box-des">Diseñamos e implementamos bases de datos relacionales seguras y confiables para almacenar y gestionar tus datos.</p>
              <img src={process.env.PUBLIC_URL + '/servicio-3.png'} alt="Servidor de bases de datos" className="text-box-img" style={{ width: '100%', height: 'auto', objectFit: 'cover', flexGrow: 1, marginTop: '30%'}} />
            </div>
            <div className="text-box">
              <h2>Desarrollo full stack</h2>
              <div className="content-txt-box-4">
                <p className="text-box-des" style={{marginRight:'20%'}}>Dominamos tanto el desarrollo front-end como back-end para crear aplicaciones web completas.</p>
                <img src={process.env.PUBLIC_URL + '/servicio-4.png'} alt="Persona desarrollando proyectos full stack" className="text-box-img1" style={{ width: '30%', height: 'auto', objectFit: 'cover', flexGrow: 1, marginTop: '-10%', marginLeft:'-10%'}} />
              </div>
            </div>
            <div className="text-box">
              <h2>Mantenimiento web</h2>
              <p className="text-box-des">Nos encargamos del mantenimiento preventivo y correctivo de tu sitio web para que siempre esté actualizado y seguro.</p>
              <img src={process.env.PUBLIC_URL + '/servicio-5.png'} alt="Checklist del mantenimiento de un sitio web" className="text-box-img" style={{ width: '90%', height: 'auto', objectFit: 'cover', flexGrow: 1, marginTop: '10px'}}/>
            </div>
            <div className="text-box">
              <h2>UX/UI</h2>
              <p className="text-box-des">Diseñamos interfaces de usuario intuitivas y atractivas que optimizan la experiencia del usuario.</p>
              <img src={process.env.PUBLIC_URL + '/servicio-6.png'} alt="Persona probando diferentes interfaces UX/UI" className="text-box-img" style={{ width: '100%', height: 'auto', objectFit: 'cover', flexGrow: 1 }}/>
            </div>
            <div className="text-box">
              <h2>Alojamiento web</h2>
              <p className="text-box-des">Ofrecemos planes de alojamiento web seguros y confiables para tu sitio web.</p>
              <img src={process.env.PUBLIC_URL + '/servicio-7.png'} alt="Servidor de alojamiento web con seguridad" className="text-box-img" style={{ width: '100%', height: 'auto', objectFit: 'cover', flexGrow: 1, marginTop: '20%' }}/>
            </div>
          </article>
        </div>
        <div className="boton-centrar">
          <Link to="/contacto">
          <button className="button-classic">Contáctanos</button>
          </Link>
        </div>
      </section>

{/*SECCION PROYECTOS*/}

<section id="section-proyectos">
      <header className="subtitle-header">
        <div className="line-subtitle"></div>
        <h3>Proyectos</h3>
        <div className="line-subtitle"></div>
      </header>

      <article className="article-proyectos">
        {[
          { id: 1, title: 'Dashboard Delivery P.S.', imgSrc: '/resources/delproyect.png' },
          { id: 2, title: 'Página Web GTI', imgSrc: '/resources/pagina-web 1.png' },
          { id: 3, title: 'Dashboard CVO', imgSrc: '/resources/freepik-export-202405221440189e0u 1.png' },
          { id: 4, title: 'Página Web IYF', imgSrc: '/resources/19_design_thingking_layout 1.png' },
        ].map((project) => (
          <div
            key={project.id}
            className="text-box-proyectos"
            onMouseEnter={() => handleMouseEnter(project.id)}
            onMouseLeave={handleMouseLeave}>
            {hoveredProject === project.id ? (
              getProjectContent(project.id)
            ) : (
              <>
                <span
                  style={{
                    writingMode: hoveredProject && hoveredProject !== project.id ? 'vertical-rl' : 'horizontal-tb',
                    transform: hoveredProject && hoveredProject !== project.id ? 'rotate(180deg)' : 'none',
                    textAlign: hoveredProject && hoveredProject !== project.id ? 'right' : 'center',
                    whiteSpace: hoveredProject && hoveredProject !== project.id ? 'nowrap' : 'normal', // Asegura que el texto pueda dividirse en líneas
                    transition: 'none',
                    overflow: 'visible',
                    display: 'block',
                    height: '100%',
                    display: 'flex',
                    justifyContent: hoveredProject ? 'center' : 'flex-start', 
                    alignItems: 'center',
                    paddingTop: hoveredProject ? '0' : '1em',
                    wordWrap: 'break-word', 
                    lineHeight: '1.2em',
                  }}
                >
                  
                  {project.title}
                  
                </span>
                
                {!hoveredProject && (
                  <img
                    src={process.env.PUBLIC_URL + project.imgSrc}
                    alt={`Imagen ${project.title}`}
                    className="text-box-img"
                    style={{
                      display: 'block',
                      margin: 'auto',
                      maxWidth: '95%',
                      maxHeight: '95%',
                      objectFit: 'contain',
                    }}
                  /> 
                )}
              </>
            )}
          </div>
        ))}
      </article>

 
      <div className='carousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/delservice.png" titulo="Dashboard Delivery P.S." />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/gtiservice.png" titulo="Página Web GTI" />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/cvoservice.png" titulo="Dashboard CVO" />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/iyfservice.png" titulo="Página Web IYF" />
          </div>
        </Carousel>
      </div>

      <div className="boton-centrar">
        <Link to="/proyectos">
        <button className="button-classic">Proyectos</button>
        </Link>
      </div>

</section>

{/*SECCION TESTIMONIOS */}

      <section id="section-testimonios">
        <header className="subtitle-header header-testimonios">
          <div className="line-subtitle"></div>
          <h3>Testimonios</h3>
          <div className="line-subtitle"></div>
        </header>
        <p>Descubre cómo <span className="font-bold">nuestros clientes están opinando</span> de nuestros servicios y únete a la creciente comunidad de<span className="font-bold"> clientes satisfechos</span></p>

      <div className='carousel carousel-testimonios'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel item-carousel-testimonios'>
          <div className="card-carousel card-1-carousel">
            <div className='header-card'> 
              <img src='/logo-delivery-ps.png' alt="Logo Delivery P.S."/> 
              <img src='/icono-delivery-ps.png' alt="Icono Delivery P.S."/> 
            </div>
            <p>
              “Nos encanta la forma rápida de entrega y la organización es buena en todo el equipo”.
            </p>
          </div>
          </div>
          <div className='itemCarousel item-carousel-testimonios'>
          <div className="card-carousel card-2-carousel">
            <div className='header-card'> 
              <img src='/logo-bm-especialistas.png' alt="Logo BM Especialistas Valuadores"/>
              <img src='/icono-bm-especialistas.png' alt="Icono BM Especialistas Valuadores"/> 
            </div>
            <p>
            “La atención de Muza fue directa, siempre veloces en la atención y en el seguimiento de actividades.
            La explicación de los servicios y aclaración de dudas siempre fue muy clara. 
            Tienen la disposición de entender lo que como empresas necesitas y te ayudan en la toma de decisiones”.
            </p>
          </div>
          </div>
          <div className='itemCarousel item-carousel-testimonios'>
          <div className="card-carousel card-3-carousel">
          <div className='header-card'> 
              <img src='/logo-rummet.png' alt="Logo Rummet"/>
              <img src='/icono-rummet.png' alt="Icono Rummet"/> 
            </div>
            <p>
              “Nos encanta la forma rápida de entrega y la organización es buena en todo el equipo”
            </p>
          </div>
        </div>
        </Carousel>
      </div>

      </section>

      {/*PIE DE PAGINA */}
      <Footer/>
      
    </div>

  );
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1
  }
};

export default App3;
