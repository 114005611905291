import React from 'react';
import '../components/ProyectoItem2.css';

const ProyectoItem2 = ({ rutaImgFondo, titulo, onClick }) => {
    return (
        <article className='proyectoItem' onClick={onClick} style={{ cursor: 'pointer' }}>
            <img className='imgFondo' src={rutaImgFondo} alt={`Imagen del proyecto ${titulo}`} />
        </article>
    );
};

export default ProyectoItem2;
