
import './App.css';

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './fonts/fonts.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';         
import 'primeicons/primeicons.css'; 
import React, { useState } from "react";
import App2 from './contacto';
import App3 from './inicio';
import Proyectos from './proyectos';
import Dashboard from './dashboard';
import Blog from './blog';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, Link } from 'react-router-dom';
import ScrollToTop from '../src/components/ScrollToTop';

function App() {
  
  return (

<BrowserRouter>
<ScrollToTop />
      <Routes>
        <Route path="/" element={<App3 />} />
        <Route path="/contacto" element={<App2 />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/proyectos/:projectName" element={<Proyectos />} /> {/* Ruta dinámica */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>

    </BrowserRouter>

  );
}

export default App;
