import './App.css';
import './fonts/fonts.css';
import MenuNav from './components/MenuNav';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProyectoItem2 from './components/ProyectoItem2';
import Footer from './components/Footer';
import './proyectos.css';

function Proyectos() {
  const [visibleProject, setVisibleProject] = useState(null);
  const [elementsVisible, setElementsVisible] = useState(true); // Controla la visibilidad de la lista de proyectos
  const navigate = useNavigate();
  const location = useLocation();

  // Mapeo de projectId a projectName
  const projectMap = {
    "dashboard-delivery-ps": 1,
    "pagina-web-gti": 2,
    "newsletter-cvo": 3,
    "dashboard-cvo": 4,
    "pagina-web-cvo": 5,
    "pagina-web-iyf": 6,
    "pagina-servicios-svo": 7,
    "redes-sociales-craftingeek": 8,
    "redes-sociales-crece-digital": 9,
    "redes-sociales-kalon": 10,
    "social-media-thera-media": 11
  };

  // Función para manejar el clic en un proyecto
  const handleProjectClick = (projectId, projectName) => {
    navigate(`/proyectos/${projectName}`); // Navega a la URL del proyecto
    setVisibleProject(projectId);
  };

useEffect(() => {
  const pathParts = location.pathname.split('/');
  const projectName = pathParts[pathParts.length - 1];
  const projectId = projectMap[projectName];

  console.log("projectName:", projectName, "projectId:", projectId);

  if (location.pathname === '/proyectos') {
    setVisibleProject(null);
    setElementsVisible(true); // Muestra la lista de proyectos
  } else if (projectId) {
    setVisibleProject(projectId);
    setElementsVisible(false); // Oculta la lista de proyectos
  } else {
    setVisibleProject(null);
    setElementsVisible(true); // Muestra la lista de proyectos si no coincide el projectName
  }
}, [location.pathname]);

return (
    <div>
      <MenuNav/>
      {/* Mostrar el proyecto "Delivery P.S." si su estado es visible */}

      {!elementsVisible && visibleProject === 1 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Delivery P.S.</h2>
          <p className='text-delivery'>En este proyecto, elaboramos un dashboard para la gestión de órdenes de paquetería y el seguimiento de las mismas. 
          <br /><br />Este dashboard está diseñado para dos tipos de usuarios: los clientes y la empresa.
           
           Los clientes pueden ver el seguimiento de sus paquetes, mientras que la empresa puede gestionar la creación de órdenes y
            envíos. Además, todo esto se visualiza desde una interfaz separada, lo que nos permite manejar la seguridad de la 
            plataforma de manera eficaz</p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/delivery1.png'/>
        <img className='img-delivery3'  src='/resources/delivery3.png'/>
        <img  className='img-delivery2' src='/resources/delivery2.png'/>
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media"   onClick={() => handleProjectClick(11, "social-media-thera-media")}/>
        <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
          </div>
        </Carousel>
        </div>
       </div>)}

      {/*segundo proyecto*/}
      {!elementsVisible && visibleProject === 2 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Pagina web GTI</h2>
          <p className='text-delivery'>Nuestra prioridad al diseñar la página web de GTI  fue crear una experiencia de usuario excepcional. A través de un diseño centrado en el 
            usuario, logramos que la navegación fuera intuitiva y agradable. Cada elemento de la página web, desde la disposición de los contenidos hasta la elección de las imágenes,
             fue cuidadosamente pensado para facilitar la interacción del usuario y guiarle hacia los objetivos de la empresa.


          <br /><br />El resultado es una página web que no solo es visualmente atractiva, sino que también es altamente funcional y adaptable a diferentes dispositivos, garantizando una experiencia óptima para todos
           los usuarios.</p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/GTI1.jpg'/>
        <img className='img-delivery3'  src='/resources/GTI2.jpg'/>
        <img  className='img-delivery2' src='/resources/GTI3.jpg'/>
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." onClick={() => handleProjectClick(1, "dashboard-delivery-ps")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." onClick={() => handleProjectClick(1, "dashboard-delivery-ps")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
          </div>
        </Carousel>
        </div>
       </div>)}
       {/*tercer proyecto*/}
       {!elementsVisible && visibleProject === 3 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Newsletter CVO</h2>
          <p className='text-delivery'>CVO nos confió la tarea de crear un newsletter que reforzara su imagen de marca y mejorara la comunicación con sus clientes. 
            Para ello, desarrollamos una estrategia de diseño y configuración que nos permitió lograr los siguientes objetivos: <br /><br />Aumento del engagement: Gracias a un diseño atractivo y contenido relevante, logramos incrementar la tasa de apertura y clics del newsletter. 
            Mayor reconocimiento de marca: Al mantener una estética coherente en todos los canales digitales, reforzamos la identidad de marca de CVO y aumentamos el reconocimiento entre los clientes.
            Mejora de la reputación online: El newsletter se convirtió en una herramienta clave para compartir noticias, novedades y contenido de valor, lo que contribuyó a posicionar a CVO como líder en su sector.
          </p>
          <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/newsletter1.jpg'/>
        <img className='img-delivery3'  src='/resources/newsletter2.jpg'/>
        
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." onClick={() => handleProjectClick(1, "dashboard-delivery-ps")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." onClick={() => handleProjectClick(1, "dashboard-delivery-ps")} />
          </div>
        </Carousel>
        </div>
       </div>)}


       {/*cuarto proyecto*/}
       {!elementsVisible && visibleProject === 4 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Dashboard CVO</h2>
          <p className='text-delivery'>Para facilitar la gestión de propiedades en renta, compra y venta, desarrollamos un dashboard intuitivo
             y visualmente atractivo. Esta herramienta, diseñada a medida para los usuarios de CVO, centraliza toda la información relevante en una única plataforma,
              permitiendo un acceso rápido y sencillo a los detalles de cada propiedad.

          <br /><br />A través de una interfaz clara y organizada, los usuarios pueden:
          <br /><br />Visualizar de manera instantánea el estado de sus propiedades, incluyendo su valor de mercado, rentabilidad y gastos asociados.
          <br /><br />Realizar búsquedas avanzadas por diversos criterios, como ubicación, tipo de propiedad, rango de precios y fecha de publicación.
          <br /><br />Generar reportes personalizados para analizar el rendimiento de su portafolio inmobiliario y tomar decisiones estratégicas.
          <br /><br /> Gestionar las tareas relacionadas con cada propiedad de forma eficiente, desde programar visitas hasta gestionar pagos y contratos.
          <br /><br />
          Al simplificar la navegación y proporcionar información precisa y actualizada, el nuevo dashboard de CVO mejora significativamente la experiencia del usuario,
           permitiéndoles dedicar más tiempo a oportunidades de inversión y menos tiempo a tareas administrativas.
          </p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/dashcvo.jpg'/>
        <img className='img-delivery3'  src='/resources/dashcvo2.jpg'/>
        <img  className='img-delivery2' src='/resources/dashcvo3.jpg'/>
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
          </div>
        </Carousel>
        </div>
       </div>)}

       {/*quinto proyecto*/}
       {!elementsVisible && visibleProject === 5 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Pagina web CVO</h2>
          <p className='text-delivery'>La creación de esta página web no solo supuso un cambio estético, sino que se convirtió en un catalizador
             para el crecimiento de nuestro negocio. Al establecer una base visual sólida y coherente, logramos:


          <br /><br/>Mayor reconocimiento de marca: La identidad visual unificada a través de todos nuestros canales digitales fortaleció la percepción de marca, generando un mayor reconocimiento y confianza en nuestros clientes.
          <br /><br /> Incremento del tráfico orgánico: La optimización de la página web para motores de búsqueda (SEO) y su diseño intuitivo contribuyeron a un aumento significativo del tráfico orgánico, lo que se tradujo en un mayor número de visitantes interesados en nuestros productos y servicios.
          <br /><br />Mayor tasa de conversión: La experiencia de usuario optimizada, junto con llamadas a la acción claras y persuasivas, impulsó un aumento en las conversiones, ya sea en forma de compras, suscripciones o solicitudes de contacto.
          <br /><br /> Reducción de los costos de adquisición de clientes: Al mejorar la eficiencia de nuestra estrategia de marketing digital, logramos reducir los costos asociados a la adquisición de nuevos clientes.
          <br /><br />Mejora de la satisfacción del cliente: Una experiencia de usuario positiva y una navegación intuitiva contribuyeron a aumentar la satisfacción de nuestros clientes, lo que se tradujo en una mayor fidelización y en un aumento en las recomendaciones.
            <br /><br/>
            En resumen, la página web se convirtió en una herramienta estratégica para impulsar el crecimiento de nuestro negocio, mejorando nuestra visibilidad en línea, optimizando la experiencia del usuario y generando un mayor retorno de la inversión.
            </p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/pagcvo.jpg'/>
        <img className='img-delivery3'  src='/resources/pagcvo2.jpg'/>
        <img  className='img-delivery2' src='/resources/pagcvo3.jpg'/>
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
          </div>
        </Carousel>
        </div>
       </div>)}

       {/*sexto proyecto*/}
       {!elementsVisible && visibleProject === 6 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Pagina web IYF</h2>
          <p className='text-delivery'>En alianza con un Partner de Google, hemos creado una plataforma de búsqueda de empleo que va más allá de conectar
             a jóvenes con oportunidades laborales. Nuestro objetivo es empoderar a la próxima generación y reducir la brecha entre la educación y el mundo laboral.


          <br /><br />¿Cómo lo logramos?
          <br /><br />Acceso equitativo: Nuestra plataforma ofrece un acceso equitativo a oportunidades laborales, sin importar el origen socioeconómico o la ubicación geográfica de los jóvenes.
          <br /><br />Desarrollo de habilidades: A través de recursos educativos y herramientas interactivas, los jóvenes pueden desarrollar las habilidades técnicas y blandas necesarias para destacar en el mercado laboral.
          <br /><br /> Mentoría y networking: Conectamos a los jóvenes con mentores y profesionales de diversos sectores, fomentando el networking y brindando orientación personalizada.
          <br /><br />Datos e insights: Utilizamos datos analíticos para identificar las tendencias del mercado laboral y ofrecer a los jóvenes información relevante sobre las habilidades más demandadas y las industrias emergentes.
          <br /><br />Colaboración con empresas: Trabajamos en estrecha colaboración con empresas para crear programas de pasantías y becas, ofreciendo a los jóvenes la oportunidad de adquirir experiencia práctica y construir una red de contactos.
          <br /><br />Al ofrecer una plataforma integral que combina búsqueda de empleo, desarrollo de habilidades y networking, contribuimos a construir un futuro más justo y equitativo para los jóvenes.
          </p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/iys.jpg'/>
        <img className='img-delivery3'  src='/resources/iys2.jpg'/>
        <img  className='img-delivery2' src='/resources/iys3.jpg'/>
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
          </div>
        </Carousel>
        </div>
       </div>)}

       {/*septimo proyecto*/}
       {!elementsVisible && visibleProject === 7 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Pagina de servicios CVO</h2>
          <p className='text-delivery'>En respuesta a la necesidad de CVO de expandir su presencia en línea y ofrecer una experiencia de usuario más completa,
             desarrollamos una solución técnica personalizada que se integró a la perfección con su sitio web existente. La nueva página de servicios, diseñada
              a partir de la estructura del newsletter y el dashboard, permite a CVO presentar de manera clara y organizada su oferta completa, incluyendo renta, compra y venta de casas.

              <br /><br />Características destacadas de la solución:
          <br /><br />Integración seamless: La nueva página se fusionó de forma fluida con el diseño y la funcionalidad existentes del sitio web, garantizando una experiencia de usuario coherente y fácil de navegar.
          <br /><br />Diseño intuitivo: La interfaz de usuario fue diseñada con el objetivo de guiar al usuario de manera intuitiva a través de los diferentes servicios ofrecidos por CVO.
          <br /><br /> Contenido optimizado: El contenido de la página fue cuidadosamente redactado y estructurado para resaltar los beneficios de cada servicio y facilitar la toma de decisiones por parte del usuario.
          <br /><br />Llamadas a la acción claras: Se incluyeron botones de llamada a la acción estratégicamente ubicados para incentivar la interacción del usuario y generar leads.
          <br /><br />Optimización para dispositivos móviles: La página fue diseñada para adaptarse a diferentes dispositivos, garantizando una experiencia de usuario óptima tanto en computadoras de escritorio como en dispositivos móviles.
          <br /><br /><br /><br />Beneficios para CVO:
          <br /><br />Mayor visibilidad de servicios: La nueva página de servicios permite a CVO mostrar de manera clara y concisa toda su oferta, aumentando la visibilidad de servicios menos conocidos.
          <br /><br />Mejora de la experiencia del usuario: La interfaz intuitiva y el contenido optimizado facilitan la navegación y la búsqueda de información, mejorando la experiencia del usuario y aumentando la satisfacción del cliente.
          <br /><br />Mayor generación de leads: Las llamadas a la acción claras y estratégicamente ubicadas incentivan la interacción del usuario y generan un mayor número de leads.
          <br /><br />Posicionamiento como expertos: La página de servicios refuerza la posición de CVO como expertos en el sector inmobiliario, aumentando su credibilidad y confianza.
          <br /><br />Al ofrecer una solución técnica personalizada y altamente funcional, hemos ayudado a CVO a alcanzar sus objetivos de negocio y a fortalecer su presencia en línea."

          </p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/sercvo.jpg'/>
        <img className='img-delivery3'  src='/resources/sercvo2.jpg'/>
        
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
          </div>
        </Carousel>
        </div>
       </div>)}

       {/*octavo proyecto*/}
       {!elementsVisible && visibleProject === 8 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Social media Craftingeek</h2>
          <p className='text-delivery'>Para potenciar la presencia en redes sociales de nuestro cliente y fortalecer su identidad de marca,
             desarrollamos una estrategia de contenido personalizada basada en su identidad visual existente. Utilizando las herramientas y recursos proporcionados por el cliente,
              creamos una serie de publicaciones originales y atractivas que reflejaban la esencia de su negocio y se alineaban con sus objetivos de comunicación.


          <br /><br />Resultados:
          <br /><br /> Aumento del engagement: Gracias a la variedad de contenidos y la interacción constante con la audiencia, logramos un incremento significativo en el número de me gusta, comentarios y compartidos.
          <br /><br />  Fortalecimiento de la marca: La coherencia visual y el mensaje claro y conciso contribuyeron a reforzar la identidad de marca del cliente y a posicionarlo como un referente en su sector.
          <br /><br />  Mayor visibilidad: Las publicaciones optimizadas para cada plataforma permitieron alcanzar a una audiencia más amplia y generar un mayor tráfico hacia el sitio web del cliente.
          <br /><br />  Entrega de un kit de contenido completo: El cliente recibió un kit de contenido listo para su publicación, lo que le permitió ahorrar tiempo y recursos.
          <br /><br />Al ofrecer una solución integral y personalizada, logramos no solo cumplir con las expectativas del cliente, sino también superarlas, brindándole una herramienta poderosa para conectar con su audiencia
           y alcanzar sus objetivos de negocio.</p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/craft.jpg'/>
        <img className='img-delivery3'  src='/resources/craft2.jpg'/>
        
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
          </div>
        </Carousel>
        </div>
       </div>)}


       {/*noveno proyecto*/}
       {!elementsVisible && visibleProject === 9 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Social media Crece digital</h2>
          <p className='text-delivery'>Thera Media lanzó una ambiciosa campaña en redes sociales con el objetivo de democratizar el emprendimiento digital.
             A través de una serie de publicaciones informativas e inspiradoras, buscamos equipar a los usuarios con los conocimientos y herramientas necesarias para dar vida a sus ideas de negocio.


          <br /><br />Nuestra estrategia se centró en:
          <br /><br /> Contenido de valor: Compartimos guías prácticas, tutoriales paso a paso y estudios de caso de emprendedores exitosos.
          <br /><br />  Lenguaje claro y accesible: Utilizamos un lenguaje sencillo y directo para que cualquier persona, independientemente de su nivel de conocimiento técnico, pudiera entender los conceptos clave.
          <br /><br />  Interacción con la comunidad: Fomentamos la participación de la audiencia a través de preguntas, encuestas y concursos, creando una comunidad de emprendedores en constante crecimiento.
          <br /><br />  Los resultados fueron más que satisfactorios:
          <br /><br />  Aumento significativo del engagement: Las publicaciones generaron un alto nivel de interacción, con un aumento considerable en los me gusta, comentarios y compartidos.
          <br /><br />  Crecimiento de la comunidad: La campaña atrajo a un gran número de nuevos seguidores interesados en el emprendimiento digital.
          <br /><br />  Mayor conciencia sobre las oportunidades del mundo digital: Los usuarios mostraron un mayor interés en explorar las posibilidades que ofrece el emprendimiento online.
          <br /><br />  En resumen, nuestra campaña en redes sociales demostró que Thera Media está comprometida con empoderar a las personas y ayudarlas a alcanzar sus metas empresariales. Al proporcionar información valiosa y crear una comunidad de apoyo, hemos contribuido a democratizar el acceso al mundo del emprendimiento digital
            </p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/crece.jpg'/>
        <img className='img-delivery3'  src='/resources/crece2.jpg'/>
        
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto10.png" titulo="Redes Sociales Kalon" onClick={() => handleProjectClick(10, "redes-sociales-kalon")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto10.png" titulo="Redes Sociales Kalon" onClick={() => handleProjectClick(10, "redes-sociales-kalon")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
          </div>
        </Carousel>
        </div>
       </div>)}


       {/*decimo proyecto*/}
       {!elementsVisible && visibleProject === 10 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Social media Kalon</h2>
          <p className='text-delivery'>Mediante una cuidadosa selección de elementos visuales, creamos una campaña que posicionó los productos de esta 
            nueva marca como una opción deliciosa y natural. La combinación de tonos cálidos, imágenes frescas y personas reales disfrutando de los productos
             en entornos naturales permitió conectar de manera profunda con los consumidores.


          <br /><br />Esta estrategia no solo mostraba los productos, sino que también contaba la historia de la marca y sus valores.
           Al representar escenas de felicidad, tranquilidad y armonía, logramos generar una conexión emocional con la audiencia y establecer a
            la marca como una opción confiable para quienes buscan un estilo de vida saludable.
          <br /><br />Los resultados fueron más que evidentes. Los consumidores percibieron los productos como una alternativa saludable y atractiva, y la campaña transmitió de manera efectiva el mensaje de la marca, generando un impacto tanto a nivel racional como emocional.</p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/kalon.jpg'/>
        <img className='img-delivery3'  src='/resources/kalon2.jpg'/>
        
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
          </div>
        </Carousel>
        </div>
       </div>)}


       {/*onceavo proyecto*/}
       {!elementsVisible && visibleProject === 11 && (
      <div>
      <div className='central-proyect'>
        <div className='text-div'>{/*div para texto con fondo transparente */}
        <h2 className='encabezadoDelivery'>Social Media Thera Media</h2>
          <p className='text-delivery'>ATrabajando en estrecha colaboración con el cliente, profundizamos en su propuesta de valor y desarrollamos una estrategia visual que
             refleja su esencia de manera auténtica.
             <br /><br /> Mediante la aplicación de principios de diseño gráfico y una cuidadosa selección de elementos visuales, transformamos la información existente en piezas
              de contenido que no solo eran estéticamente agradables, sino que también comunicaban de manera efectiva los servicios ofrecidos por la agencia.
              <br /><br />El resultado fue una identidad digital más sólida y un aumento significativo en el engagement con la audiencia. Los seguidores de la agencia comenzaron a interactuar más con las publicaciones, compartiendo y comentando el contenido. Además, la nueva imagen visual contribuyó a posicionar a la agencia como un referente en su sector, atrayendo a nuevos clientes y fortaleciendo su reputación en el mercado.
              </p>
            <img className='logoMuza2delivery' src='/resources/logoMuza2.png'/>
        </div>
        <div className='img-div'>{/*div para img delivery */}
        <img className='img-delivery1'  src='/resources/media.jpg'/>
        <img className='img-delivery3'  src='/resources/media2.jpg'/>
        
        </div>
      </div>
      <h1 className='encabezadoproyect'>Otros proyectos</h1>
      <div className='proyectosDivdelivery displaydelivery'>
        {/* <ProyectoItem1 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." rutaImgSecundaria="/logo192.png" info="Este es un proyecto interesante." /> */}
        <ProyectoItem2 rutaImgFondo="/resources/proyecto10.png" titulo="Redes Sociales Kalon" onClick={() => handleProjectClick(10, "redes-sociales-kalon")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
        <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
       </div>
       <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto10.png" titulo="Redes Sociales Kalon" onClick={() => handleProjectClick(10, "redes-sociales-kalon")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
          </div>
        </Carousel>
        </div>
       </div>)}

      {/*Vista principal*/}
      {elementsVisible && (
        <div>
          <header className='subtitle-header'>
            <h3>Proyectos</h3>
          </header>
          <p id='p-proyectos'>
            <span className='fontBold'>Muza</span> ha trabajado con una <span className="fontBoldBlue">amplia gama de clientes</span>, desde pequeñas empresas hasta grandes corporaciones. <br /> Hemos creado muchas cosas para una variedad de industrias.
          </p>

      <div className='proyectosCarousel'>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." onClick={() => handleProjectClick(1, "dashboard-delivery-ps")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
          </div>
          <div className='itemCarousel'>
            <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
          </div>
        </Carousel>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
          </div>
        </Carousel>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
          </div>
        </Carousel>
        <Carousel responsive={responsive}>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto10.png" titulo="Redes Sociales Kalon" onClick={() => handleProjectClick(10, "redes-sociales-kalon")} />
          </div>
          <div className='itemCarousel'>
          <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
          </div>
          
        </Carousel>
      <div className="boton-centrar">
        <Link to="/contact">
          <button className="button-classic">Contacto</button>
        </Link>
      </div>

    </div>

    <div className='proyectosDiv'>
      <ProyectoItem2 rutaImgFondo="/resources/proyecto1.png" titulo="Dashboard Delivery P.S." onClick={() => handleProjectClick(1, "dashboard-delivery-ps")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto2.png" titulo="Página Web GTI" onClick={() => handleProjectClick(2, "pagina-web-gti")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto3.png" titulo="Newsletter CVO" onClick={() => handleProjectClick(3, "newsletter-cvo")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto4.png" titulo="Dashboard CVO" onClick={() => handleProjectClick(4, "dashboard-cvo")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto5.png" titulo="Página Web CVO" onClick={() => handleProjectClick(5, "pagina-web-cvo")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto6.png" titulo="Página Web IYF" onClick={() => handleProjectClick(6, "pagina-web-iyf")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto7.png" titulo="Página Servicios SVO" onClick={() => handleProjectClick(7, "pagina-servicios-svo")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto8.png" titulo="Redes Sociales Craftingeek" onClick={() => handleProjectClick(8, "redes-sociales-craftingeek")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto9.png" titulo="Redes Sociales Crece Digital" onClick={() => handleProjectClick(9, "redes-sociales-crece-digital")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto10.png" titulo="Redes Sociales Kalon" onClick={() => handleProjectClick(10, "redes-sociales-kalon")} />
      <ProyectoItem2 rutaImgFondo="/resources/proyecto11.png" titulo="Social Media Thera Media" onClick={() => handleProjectClick(11, "social-media-thera-media")} />
    </div>
    <img className='logoMuza2' src='/resources/logoMuza2.png'/>
    </div>
    )}
      <Footer/>
      
    </div>
  );
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1
  }
};
export default Proyectos;